<template>
    <div>
        <!-- BANNER -->
        <div>
            <!-- <b-carousel id="carousel-1" :interval="4000" indicators >
                <b-carousel-slide v-for="item in banners" :img-src="item.banner_picture"></b-carousel-slide>
            </b-carousel> -->
            <swiper :options="swiperOptionsBanner" class="w-100">
                <swiper-slide v-for="item in banners" :key="index" class="rounded swiper-shadow">
                    <img style="height:200px;object-fit: cover;" class="rounded w-100" :src="item.banner_picture" />
                </swiper-slide> 
            </swiper>
        </div>
        <!-- END BANNER -->

        <!-- SEARCH BAR -->
        <!-- <div class="px-2 my-2">
            <div class="input-group search">
                <span class="input-group-text"><i class="bx bx-search"></i></span>
                <input type="text" class="form-control" placeholder="What are you looking for...">
            </div>
        </div> -->
        <!-- END SEARCHBAR -->

        <!-- CATEGORIES -->
        <div class="d-flex align-items-center justify-content-center flex-wrap px-2 my-2 mt-3" style="gap:10px 15px">
            <!-- <div class="rounded-circle d-flex" style="background-color: #FFAD32;width: 55px;height:55px;">
                <img src="@/static/img_assets/chat.png" class="w-100 m-auto" style="max-width: 30px;" />
            </div> -->
            <router-link to="/shelter" class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #FFAD32;width: 55px;height:55px;">
                    <img src="@/static/img_assets/shelter.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Shelter</div>
            </router-link>
            <!-- <router-link to="/shop/products" class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #FFAD32;width: 55px;height:55px;">
                    <img src="@/static/img_assets/pet-shop.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Shop</div>
            </router-link> -->
            <div class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #686569;width: 55px;height:55px;">
                    <img src="@/static/img_assets/pet-shop.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Shop</div>
            </div>
            
            <router-link to="/homepage/services" class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #FFAD32;width: 55px;height:55px;">
                    <img src="@/static/img_assets/hotel.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Services</div>
            </router-link>
            <router-link to="/vet/home" class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #FFAD32;width: 55px;height:55px;">
                    <img src="@/static/img_assets/vet.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Vet</div>
            </router-link> 
        </div>
        <!-- END CATEGORIES -->

        <!-- LEARN MORE -->
        <div class="px-2 mb-2 mt-3">
            <swiper :options="swiperOptions" class="w-100">
                <swiper-slide v-for="(data, index) in ads" :key="index" class="rounded swiper-shadow">
                    <img style="height:200px;object-fit: cover;" class="rounded w-100" :src="data.ads_picture" />
                </swiper-slide>
                <div slot="button-next" class="swiper-button-next d-flex"><i
                        class="m-auto bx bx-chevron-right text-dark m-auto" style="font-size:28px"></i></div>
                <div slot="button-prev" class="swiper-button-prev d-flex"><i
                        class="m-auto bx bx-chevron-left text-dark m-auto" style="font-size:28px"></i></div>
            </swiper>
        </div>
        <!-- END LEARN MORE -->

        <!-- PRODUCT -->
        <!-- <div class="px-2 mt-4 mb-2">
            <h4 class="font-weight-bolder text-white h3">Find Out More Products</h4>
            <div class="row" style="gap:10px 0">
                <div class="col-6">
                    <div class="bg-white p-1 position-relative d-flex" style="border-radius: 10px;">
                        <i class="position-absolute bx bxs-purchase-tag"
                            style="color: #FFAD32;font-size: 28px;top:10px;left:10px"></i>
                        <img src="https://cdn.onemars.net/sites/whiskas_id_xGoUJ_mwh5/image/large_new-pack-whk_hairball_1652272467429.png"
                            class="w-100 m-auto" style="width:200px" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="bg-white p-1 position-relative d-flex" style="border-radius: 10px;">
                        <i class="position-absolute bx bxs-purchase-tag"
                            style="color: #FFAD32;font-size: 28px;top:10px;left:10px"></i>
                        <img src="https://cdn.onemars.net/sites/whiskas_id_xGoUJ_mwh5/image/large_new-pack-whk_hairball_1652272467429.png"
                            class="w-100 m-auto" style="width:200px" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="bg-white p-1 position-relative d-flex" style="border-radius: 10px;">
                        <i class="position-absolute bx bxs-purchase-tag"
                            style="color: #FFAD32;font-size: 28px;top:10px;left:10px"></i>
                        <img src="https://cdn.onemars.net/sites/whiskas_id_xGoUJ_mwh5/image/large_new-pack-whk_hairball_1652272467429.png"
                            class="w-100 m-auto" style="width:200px" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="bg-white p-1 position-relative d-flex" style="border-radius: 10px;">
                        <i class="position-absolute bx bxs-purchase-tag"
                            style="color: #FFAD32;font-size: 28px;top:10px;left:10px"></i>
                        <img src="https://cdn.onemars.net/sites/whiskas_id_xGoUJ_mwh5/image/large_new-pack-whk_hairball_1652272467429.png"
                            class="w-100 m-auto" style="width:200px" />
                    </div>
                </div>
            </div>
        </div> -->
        <!-- END PRODUCT -->

    </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

import store from '@/store'

export default {
    components: {
        Swiper, SwiperSlide,
        BCarousel, BCarouselSlide
    },
    computed: {
        banners() {
            return store.state.banners.BANNERS || []
        },
        ads() {
            return store.state.banners.ADS || []
        },
    },
    mounted() {
        store.dispatch('banners/GetBanners')
        store.dispatch('banners/GetAds')
    },
    data() {
        return {
            learn_more_banner: [
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870' },
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-banner-design-template-4c29604da3e3601ec0a5d464e2cbeb4b_screen.jpg?ts=1621925071' }
            ],
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                autoplay: {
                    delay: 3000,
                },
            },
            swiperOptionsBanner: {
                slidesPerView: 1,
                spaceBetween: 10, 
                autoplay: {
                    delay: 3000,
                },
            },
        }
    }
}

</script>

<style> .input-group.search span {
     border-right: unset;
     border-color: #FFAD32;
     border-radius: 10px 0 0 10px;
     font-size: 24px;
     color: #FFAD32;
     background-color: transparent;
 }

 .input-group.search input {
     margin-left: 0;
     padding-left: 0;
     border-left: unset;
     border-radius: 0 10px 10px 0;
     border-color: #FFAD32;
     height: 40px;
     color: white;
     background-color: transparent !important;
 }

 .swiper-button-prev,
 .swiper-button-next {
     --swiper-theme-color: transparent;
     background: #ffffff;
     box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
     width: 40px;
     height: 40px;
     border-radius: 50%;
     padding: 0 !important;
 }

 .swiper-button-prev::after,
 .swiper-button-next::after {
     content: unset !important;
 }
</style> 